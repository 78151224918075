import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div name="about" className='w-full h-screen bg-[#0a192f] text-gray-300 font-sans'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8'>
                        <p className='text-4xl font-bold inline border-b-8 border-[#C23B22]'>About</p>
                    </div>

                    <div>

                    </div>

                </div>

                <div className='max-w-[1000px] w-full px-4 grid sm:grid-cols-2 gap-8'>
                    <div className='sm:text-right text-4xl font-bold' data-aos='fade-right' data-aos-duration='1000'>
                        <p>What is <span className='text-[#C23B22]'>TechXtents</span>Please take a look some of our Projects, <span className='text-[#C23B22]'>Thank You</span>.</p>
                    </div>
                    <div data-aos='fade-up' data-aos-duration='1000'>
                        <p>
                        As a .. Services Provicer, WE enjoy tackling new challenges and continuously expanding my skillset.we take pride in building strong and lasting relationships with our clients. Our diverse portfolio spans various industries, showcasing our ability to adapt and deliver customized solutions for different business needs
                            With <span className='text-yelow-500'>Expertiese </span>
                            in HTML, CSS, JavaScript, and TypeScript, coupled with a flair for creating visually
                            stunning interfaces
                        </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default About
